import Vue from 'vue'

export default new Vue({
    methods: {
        image(src, width, height) {
            // const multiplier = window.devicePixelRatio;
            const multiplier = 1;
            let tr_str = '';

            if (width) {
                tr_str = "w_" + Math.floor(width * multiplier)
            }
            if (height) {
                if (width) {
                    tr_str += ",h_" + Math.floor(height * multiplier)
                }
                else {
                    tr_str = "h_" + Math.floor(height * multiplier)
                }
            }

            // Check if the link is cloudinary, if so return the link without modification
            if (src.indexOf('cloudinary.com') > -1) {
                return src.replace('upload', 'upload/' + tr_str)
            }

            return process.env.VUE_APP_IMAGE_URL + tr_str + '/f_auto/' + src;
        }
    }
})