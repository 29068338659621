<template>
  <div class="content">
    <div class="row">
      <div class="col-4">
        <input type="text" v-model="query" placeholder="Search product" class="form-control" v-on:keyup="triggerSearch()" />
      </div>
      <div class="col"></div>
      <div class="col-auto">
        <a class="blue" href="#" @click.prevent="query= null, getProducts()">Reset Filter</a>
      </div>
    </div>

    <div class="row" v-if="!productsReady">
      <div class="col">
        <div class="mt-4">
          <div class="loading-placeholder">
            <div class="spinner-border spinner-border-sm ms-2 big-spinner light-spinner" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="productsReady">
      <div class="col">
        <div class="mt-2">
          <div class="table-container">
            <table class="table align-middle">
              <thead class="head">
                <tr>
                  <th></th>
                  <th>Product</th>
                  <th class="text-end pe-5">Price</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, key) in products.data" :key="key">
                  <td>
                    <div class="flex items-center">
                      <img v-if="product.images[0]" :src="image(product.images[0].origin_src, 150)" alt loading="lazy" width="50px" />
                      <div aria-hidden="true"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-column">
                      <router-link class="bold blue" :to="'/products/'+product.id">{{ product.name }}</router-link>
                      <span class="pale">{{product.sku}}</span>
                    </div>
                  </td>
                  <td class="pale text-end pe-5">{{ formatValue(product.price * 100, "money") }}</td>
                  <td class="text-center">
                    <a class="vave-btn" :href="'/products/'+product.id">
                      <i class="fa fa-eye me-1"></i>
                      Open
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <pagination class="paginator" :limit="4" align="center" :data="products" @pagination-change-page="getProducts"></pagination>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "./../http.js";
import pagination from "laravel-vue-pagination";
import imageProxy from "./../imageProxy";
import { formatValue } from "./../formatters";

export default {
  data() {
    return {
      products: {},
      query: "",
      per_page: 20,
      productsReady: false,
    };
  },
  mounted() {
    this.getProducts();
  },
  components: {
    pagination,
  },
  methods: {
    formatValue,
    image: imageProxy.image,
    getProducts(page = 1) {
      this.products = {};
      axios
        .get("/api/products?per_page=" + this.per_page + "&page=" + page)
        .then(
          (r) => {
            this.productsReady = true;
            if (r.status == 200) {
              this.products = r.data;
            }
          },
          (e) => {
            this.productsReady = true;
            console.log(e);
          }
        );
    },
    triggerSearch: _.debounce(function () {
      this.output = "";
      this.products = [];
      this.productsReady = false;
      axios
        .get("/api/products/search?per_page=99&page=1&q=" + this.query)
        .then((r) => {
          this.productsReady = true;
          if (r.status == 200) {
            this.products = r.data;
          }
        });
    }, 500),
  },
};
</script>
